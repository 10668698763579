.Accordion_accordion__79fvQ {
    margin-left: 0;
    margin-bottom: 0;
    list-style-type: none;
    padding: 0;
    text-align: left;
}

    .Accordion_accordion__79fvQ button {
    outline-width: 2px;
    outline-offset: 2px;
}

    .Accordion_accordion__79fvQ button:focus {
    outline-color: #007ACC;
}

.Accordion_accordion__trigger__cX2o0 {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    text-align: left;
}

.Accordion_accordion__item__K3aSN {
    display: flex;
    flex-direction: column;
    gap: 0;

    padding-top: 24px;
    margin-top: 24px;
    border-top: 1px solid #CAD8E2;
}

.Accordion_accordion__item__K3aSN:first-child {
        margin-top: 0;
        padding-top: 0;
        border-top: none;
    }

.Accordion_accordion__item__K3aSN.Accordion_accordion__item_is_active__bRxGw .Accordion_accordion__icon__oMUml {
            transform: rotateZ(90deg);
        }

.Accordion_accordion__item__K3aSN {

    transition: gap 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.Accordion_accordion__icon__oMUml {
    transition: transform 0.6s cubic-bezier(0.23, 1, 0.32, 1), fill 0.4s linear;
}

.Accordion_accordion__content__Fzl8O {
    overflow: hidden;
    transition: max-height 0.8s cubic-bezier(0.23, 1, 0.32, 1), padding 0.8s cubic-bezier(0.23, 1, 0.32, 1),
        margin-top 0.8s cubic-bezier(0.23, 1, 0.32, 1), margin-left 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.Accordion_accordion__content__Fzl8O.Accordion_is_collapsed__GCpM_ {
        max-height: 0;
    }

.Accordion_accordion__content__Fzl8O > div > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16px * var(--tw-space-y-reverse));
}

.Accordion_accordion__content__Fzl8O > div {

        padding-top: 24px
    }

.Accordion_accordion__disabled__q_tVt {
    border: none;
}

.Accordion_accordion__disabled__q_tVt .Accordion_accordion__content__Fzl8O {
        padding-left: 0;
        padding-right: 0;
        margin: 0;
    }

@media screen and (min-width: 767px) {

.Accordion_accordion__disabled__q_tVt .Accordion_accordion__content__Fzl8O {
            border-top: 0;
            margin-top: 0
    }
        }

.Accordion_accordion__disabled__q_tVt .Accordion_accordion__trigger__cX2o0 {
        pointer-events: none;
        padding: 0;
    }

